<template>
	<div class="blog-card">
		<div class="img-container" :style="{'background-image': 'url(' + image + ')'}">
			<div class="inner">
				<div class="title">{{title}}</div>
			</div>
		</div>
		<div class="info">
			<span class="date caption">{{formatDate(date * 1000)}}</span>
			<span class="read-time caption">{{read_time}}</span>
		</div>
		<div class="org caption">{{org}}</div>
		<div class="des body-large" v-html="des">
			{{des}}
		</div>
		<router-link class="link" :to="{ name: 'Blog Article', params: { id: id }}">
			<button class="button">
				<img src="@/assets/blog/blog-list/read-more-icon.png" class="btn-book-icon" />
				read more
			</button> 
		</router-link>
	</div>
</template>

<script>
import helpers from '@/helpers/helpers.js';

export default {
	props: [
		'id',
		'img',
		'title',
		'date',
		'read_time',
		'org',
		'des'
	],
	methods: {
		formatDate: (date) => helpers.formatDate(date),
		getImage: (img, includesExtension) => helpers.getImage(img, includesExtension),
	},
	computed: {
		image() {
			if(this.img) {
				return this.img
			} else {
				return this.getImage('banners/placeholder.webp', true);
			}
		}
	}
}
</script>

<style scoped>
	.blog-card{
		width: 90%;
		margin: auto;
		margin-top: 41px;
		margin-bottom: 47px;
		max-width: 370px;
		text-align: left;
		vertical-align: top;
	}
	.blog-card .img {
		border-radius: 20px;
	}
	.blog-card .img-container {
		height: 255px;
		border-radius: 20px;
		background-repeat: no-repeat;
		position: relative;
		overflow: hidden;
		background-size: cover;
	}
	.blog-card .img-container .inner {
		height: 100%;
		position: relative;
		background: linear-gradient(180deg, rgba(0, 0, 0, 0) 45%, rgba(0, 0, 0, 0.9) 100%);
	}
	.blog-card .title {
		text-align: left;
		margin-left: 13px;
		font-style: normal;
		font-weight: bold;
		font-size: 28px;
		line-height: 36px;
		color: #FFFFFF;
		position: absolute;
		bottom: 35px;
	}
	.blog-card .info {
	padding-top: 10px;
	}
	.blog-card .date {
		color: #000000;
		margin-left: 14px;
	}
	.blog-card .org{
		color: #118AB2;
		padding-bottom: 9px;
		text-align: left;
		margin-left: 14px;
	}
	.blog-card .read-time {
		color: #000000;
		float: right;
		margin-right: 20px;
	}
	.blog-card .des {
		color: #000000;
		margin-right: 5px;
		padding-left: 14px;
		width: 100%;
		padding-right: 20px;
		box-sizing: border-box;
		max-height: 135px;
		overflow: hidden;
	}

	.blog-card .button {
		font-size: 14px;
		line-height: 0;
		width: 116px;
		height: 23px;
		margin-top: 5px;
		margin-left: 14px;
	}
	.blog-card .btn-book-icon {
		width: 17.5px;
		height: 13.72px;
		margin-bottom: -3px;
		margin-right: 6px;
	}
	@media (min-width: 960px) { /* Large Tablets + Desktop */
		.blog-card {
			margin: initial;
			display: inline-block;
			margin: 10px 10px;
		}	
	}
</style>

