<template>
	<div class="outer-wrapper">

		<div class="inner-wrapper" >

			<div class="heading-section">
				<h1 class="heading3">{{blog_list_title}}</h1>
				<div class="blog-list-des caption">{{blog_list_des}}</div>
			</div>

		</div>

		<div class="blog-list">
			<Loader :loading="isLoading" :error="error" @refresh="fetchPosts"/>
			<div class="blog-cards-wrapper" v-if="!isLoading">
				<BlogSummaryCards
					v-for="blog in blogs"
					v-bind:key="blog.id"
					:id="blog.id"
					:img="blog.fields.featured_image"
					:title="blog.fields.heading"
					:date="blog.fields.show_date"
					:read_time="blog.fields.read_time"
					:org="blog.fields.org"
					:des="blog.fields.intro"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import BlogSummaryCards from '@/components/blogs/BlogSummaryCard';
import Menu from '@/components/Menu.vue'
import TopLeftIcon from '@/components/TopLeftIcon'
import Loader from '@/components/Loader'
import { mapState, mapActions } from 'vuex';

export default {
	components: {
		BlogSummaryCards,
		Menu,
		TopLeftIcon,
		Loader
	},
	data() {
		return { 
			blog_list_title: "Community Stories",
			blog_list_des: "Read all about the things you care about, from community projects to charties.",
			isLoading: false,
		}
	},
	computed: {
		...mapState({
			alert: state => state.alert,
			blogs: state => state.blogs.blogs
		}),
	},
	methods: {
		...mapActions({
			alertError: "alert/error",
			fetchBlogs: "blogs/fetchBlogs"
		}),
		fetchPosts: function() {
			if(this.blogs.length == 0) {
				this.isLoading = true;
			}
			this.fetchBlogs()
				.then((response) => {
					this.isLoading = false;
					this.error = false;
				},
				error => {
					this.alertError("Failed to load...");
					this.error = "Failed to load...";
					this.isLoading = false;
				});
		},
	},
	created() {
		this.$emit('update:topLayoutSettings', {
			left: {
				type: 'back',
				route: this.$route.query.redirect ? this.$route.query.redirect : { name: 'home' },
			},
			right: {
				type: 'menu',
			},
		});

		this.fetchPosts();
	}
}
</script>

<style scoped>
/* .blog-list-header {
	margin: auto;
  	text-align: center;
	width: 71%;
	max-width: 370px;
  	overflow: hidden;
	padding-top: 36px;
} */
/* .blog-list-des {
	margin-left: 10%;
	margin-right: 10%;
	margin-top: -12px;
} */
@media (min-width: 900px) { /* Large Tablets + Desktop */
	.blog-cards-wrapper {
		text-align: center;
		padding-top: 20px;
	}
}
</style>